<template>
  <!--begin::List Widget 4-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-3">
      <h3 class="card-title font-weight-bolder text-dark">Chatbot 1</h3>
      <div class="card-toolbar">
        <Dropdown2></Dropdown2>
      </div>
    </div>
    
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <template v-for="(item, i) in list">
        <!--begin::Item-->
        <div class="d-flex align-items-center mb-10" v-bind:key="i">
        
          <!--begin::Checkbox-->
           <div class="symbol symbol-45 symbol-light mr-5">
              <span class="symbol-label">
                <inline-svg
                  :src="item.svg"
                  class="h-50 align-self-center"
                ></inline-svg>
              </span>
            </div>
          <!--end::Checkbox-->


          <!--begin::Text-->
          <div class="d-flex flex-column flex-grow-1">
            <a
              href="#"
              class="text-success text-hover-primary font-weight-bold font-size-lg mb-1"
            >
              {{ item.title }}
            </a>
            <span class="text-muted font-weight-bold">
              {{ item.desc }}
            </span>
          </div>
          <!--end::Text-->
          <!--begin::Dropdown-->
          <Dropdown2></Dropdown2>
          <!--end::Dropdown-->
        </div>
        <!--end:Item-->
      </template>
    </div>
    <!--end::Body-->
  </div>
  <!--end: List Widget 4-->
</template>

<script>
import Dropdown2 from "@/view/content/dropdown/Dropdown2.vue";
import Dropdown4 from "@/view/content/dropdown/Dropdown4.vue";
import { mapGetters } from "vuex";

export default {
  name: "widget-4",
  data() {
    return {
      list: [
        {
          title: "Online",
          desc: "67984461664",
          svg: "media/svg/login/codeigniter.svg",
          style: "success"
        },
        {
          title: "Online",
          desc: "Facebook",
          svg: "media/svg/logos/facebook-3.svg",
          style: "primary"
        },
       
      ]
    };
  },
  components: {
    Dropdown2,
    Dropdown4
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  }
};
</script>
